import React, { useRef, useState } from 'react'
import TimeField from 'react-simple-timefield'
import classNames from 'classnames'
import SimpleSelectDropDownList from '../../Commons/SimpleSelectDropDownList'
import { PaymentMethods } from '../../../services/PaymentMethods'
import { NumberInput } from '../../Commons/NumberInput'
import { formatPrice } from '../../../tools/Utility'
import TextAreaForms from '../../Commons/FormTextArea'
import DeleteButton from '../../Commons/Buttons/DeleteButton'
import { formatTimeInput } from '../../../services/dateUtils'
import ScanContainer from '../Camera/ScanContainer'
import { RUNINFO_ACTIONS } from '../../../services/Reducers/RunInformationReducer'
import { Driver } from '../../../services/API/Entities/driver'
import UploadDocument from '../Files/UploadDocument'
import ScanDocument from '../Camera/ScanDocument'
import SignatureDocument from '../SignatureDocument'
import ActionModal from '../../Modal/ActionModal'
import SignatureViewer from '../../Commons/SignatureViewer'
import Loader from '../../Commons/Loaders/Loader'
import { Trash, Xmark } from 'iconoir-react'
const OptionForm = ({
    dispatch,
    isLoadingSignature,
    runInfo,
    changeRunStatus,
    isEffectiveArrivingTimeBeforeEffectiveDepartureTime,
    allowedToChangeHours,
}) => {
    const [showValidationModal, setShowValidationModal] = useState(false)
    // Pour l'affichage en plein écran d'une image
    const [fullscreenImage, setFullscreenImage] = useState(null)
    // Pour afficher le scanner (ScanContainer) dans une modale
    const [deletingDocument, setDeletingDocument] = useState(false)
    const [showScanner, setShowScanner] = useState(false)
    // Permet de préciser quel type de document scanner (invoice ou prescription)
    const [scannerDocType, setScannerDocType] = useState(null)
    const [docType, setDoctype] = useState(null)
    const [isDeleting, setIsDeleting] = useState(false)
    const scrollContainerRef = useRef(null)

    // Ajoutez simplement cette fonction dans votre composant (par ex. OptionForm) :
    const handleScroll = (direction) => {
        if (scrollContainerRef.current) {
            const scrollAmount = 250 // Adjust this value based on your needs
            const newScrollLeft =
                scrollContainerRef.current.scrollLeft +
                (direction === 'right' ? scrollAmount : -scrollAmount)
            scrollContainerRef.current.scrollTo({
                left: newScrollLeft,
                behavior: 'smooth',
            })
        }
    }
    const handleDeleteDocument = async () => {
        closeValidationModal()
        setIsDeleting(true)
        const data = {
            runId: runInfo.id,
            docType: docType,
        }
        try {
            await Driver.deleteFromS3(data)

            if (docType === 'invoice') {
                dispatch({
                    type: RUNINFO_ACTIONS.SET_INVOICE_URL,
                    payload: null,
                })
            } else {
                // remove URL
                dispatch({
                    type: RUNINFO_ACTIONS.REMOVE_DOCUMENT_URL,
                    payload: docType,
                })
            }
        } catch (error) {
            console.error('Erreur lors de la suppression du document :', error)
        } finally {
            setIsDeleting(false)
        }
    }

    const openScanner = (docType) => {
        setScannerDocType(docType)
        setShowScanner(true)
    }

    const openValidationModal = (docUrl) => {
        setDoctype(docUrl)
        setShowValidationModal(true)
    }
    const closeValidationModal = () => {
        setDoctype(null)
        setShowValidationModal(false)
    }

    const renderPMTSection = () => {
        return (
            <div className="space-y-2">
                {isDeleting ? (
                    <Loader className="h-5 w-5 animate-spin" />
                ) : (
                    <>
                        <div className="mb-2 flex items-center">
                            <h3 className="font-bold text-gray-600">PMT</h3>
                            <span className="ml-2 text-sm text-gray-500">
                                ({runInfo.documentsUrl?.length}/3)
                            </span>
                        </div>

                        {runInfo.documentsUrl?.length > 0 && !showScanner ? (
                            <div className="relative">
                                {runInfo.documentsUrl.length > 0 && (
                                    <>
                                        <button
                                            onClick={() => handleScroll('left')}
                                            className="absolute left-0 top-1/2 z-10 -translate-y-1/2 bg-white bg-opacity-75 p-2 shadow-md"
                                        >
                                            <i className="fas fa-chevron-left" />
                                        </button>
                                        <button
                                            onClick={() =>
                                                handleScroll('right')
                                            }
                                            className="absolute right-0 top-1/2 z-10 -translate-y-1/2 bg-white bg-opacity-75 p-2 shadow-md"
                                        >
                                            <i className="fas fa-chevron-right" />
                                        </button>
                                    </>
                                )}

                                <div
                                    ref={scrollContainerRef}
                                    className="scrollbar-hide flex space-x-4 overflow-x-auto"
                                >
                                    {runInfo.documentsUrl.map(
                                        (document, index) => (
                                            <div
                                                key={index}
                                                className="relative flex-none"
                                            >
                                                <img
                                                    src={document.url}
                                                    alt={`PMT ${index + 1}`}
                                                    className="h-48 w-48 cursor-pointer rounded-lg border object-contain"
                                                    onClick={() =>
                                                        setFullscreenImage(
                                                            document.url
                                                        )
                                                    }
                                                />
                                                <button
                                                    onClick={() =>
                                                        openValidationModal(
                                                            document.id
                                                        )
                                                    }
                                                    className="absolute -top-2 right-0 rounded-full bg-white p-1 text-red-500 shadow-md"
                                                    disabled={isDeleting}
                                                >
                                                    {isDeleting ? (
                                                        <Loader className="h-5 w-5 animate-spin" />
                                                    ) : (
                                                        <Xmark
                                                            className={
                                                                'h-6 w-6'
                                                            }
                                                        />
                                                    )}
                                                </button>
                                            </div>
                                        )
                                    )}

                                    {runInfo.documentsUrl.length < 3 && (
                                        <div className="relative w-60 flex-none object-contain">
                                            <UploadDocument
                                                docType="prescription"
                                                dispatch={dispatch}
                                                runId={runInfo.id}
                                            />
                                            <ScanDocument
                                                docType="prescription"
                                                openScanner={openScanner}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="grid w-full grid-cols-2 gap-2">
                                <UploadDocument
                                    docType="prescription"
                                    dispatch={dispatch}
                                    runId={runInfo.id}
                                />
                                <ScanDocument
                                    docType="prescription"
                                    openScanner={openScanner}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        )
    }

    return (
        <div className="mx-5 mb-5 flex w-11/12 shrink-0 snap-center snap-always flex-col space-y-10 overflow-y-scroll rounded-xl bg-white p-5 shadow-mobile">
            {/* En-tête */}
            <div className="flex w-full items-center justify-between pb-5">
                <p className="font-bold text-gray-600">Options</p>
                <i className="fas fa-info fa-lg pl-4" />
            </div>

            {/* Gestion des heures */}
            {allowedToChangeHours && (
                <div className="flex w-full justify-between">
                    <div className="flex flex-col items-center pr-4">
                        <p className="text-lg font-bold text-gray-600">
                            Départ effectif
                        </p>
                        <TimeField
                            value={formatTimeInput(
                                runInfo.effectiveDepartureTime
                            )}
                            onChange={(e) =>
                                dispatch({
                                    type: RUNINFO_ACTIONS.SET_EFFECTIVE_DEPARTURE_TIME,
                                    payload: e.target.value,
                                })
                            }
                            input={
                                <input className="h-10 w-full border-b-2 text-center leading-tight hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none" />
                            }
                            colon=":"
                        />
                    </div>
                    <div className="flex flex-col items-center">
                        <p className="text-lg font-bold text-gray-600">
                            Arrivée effective
                        </p>
                        <TimeField
                            value={formatTimeInput(
                                runInfo.effectiveArrivingTime
                            )}
                            onChange={(e) =>
                                dispatch({
                                    type: RUNINFO_ACTIONS.SET_EFFECTIVE_ARRIVING_TIME,
                                    payload: e.target.value,
                                })
                            }
                            input={
                                <input
                                    className={classNames(
                                        'h-10 w-full border-b-2 text-center leading-tight',
                                        {
                                            'border-red-500':
                                                isEffectiveArrivingTimeBeforeEffectiveDepartureTime(),
                                            'hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none':
                                                !isEffectiveArrivingTimeBeforeEffectiveDepartureTime(),
                                        }
                                    )}
                                />
                            }
                            colon=":"
                        />
                    </div>
                </div>
            )}

            {/* -------------------- Partie Gestion des Documents (changé) -------------------- */}
            <div className="space-y-8">
                {renderPMTSection()}
                {/* Facturette (docType = 'invoice') */}
                <div>
                    <h3 className="mb-2 font-bold text-gray-600">Ticket</h3>
                    {isDeleting ? (
                        <Loader className="h-5 w-5 animate-spin" />
                    ) : (
                        <>
                            {runInfo.invoiceUrl ? (
                                <div className="flex items-center justify-between space-x-2">
                                    <img
                                        src={runInfo.invoiceUrl}
                                        alt="Facturette"
                                        className="h-48 w-48 cursor-pointer rounded-lg"
                                        onClick={() =>
                                            setFullscreenImage(
                                                runInfo.invoiceUrl
                                            )
                                        }
                                    />
                                    <button
                                        onClick={() =>
                                            openValidationModal('invoice')
                                        }
                                        className="text-red-500"
                                    >
                                        <i className="fas fa-trash fa-lg" />
                                    </button>
                                </div>
                            ) : (
                                <div className="grid w-full grid-cols-2 gap-2">
                                    <UploadDocument
                                        docType="invoice"
                                        dispatch={dispatch}
                                        runId={runInfo.id}
                                    />
                                    <ScanDocument
                                        docType={'invoice'}
                                        openScanner={openScanner}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>

            {/* -------------------- Paiement et Montant -------------------- */}
            {runInfo.runObject['@id'].includes('Course') && (
                <div className="flex flex-col">
                    <p className="pb-1 font-bold text-gray-600">
                        Méthode de paiement
                    </p>
                    <SimpleSelectDropDownList
                        options={PaymentMethods}
                        value={runInfo.paymentMethod}
                        handleOptionChange={(e) => {
                            dispatch({
                                type: RUNINFO_ACTIONS.SET_PAYMENT_METHOD,
                                payload: e,
                            })
                        }}
                        isSearchable={true}
                    />
                    <p className="pb-1 pt-6 font-bold text-gray-600">Montant</p>
                    <div className="flex items-center pl-4">
                        <NumberInput
                            value={runInfo.amount}
                            placeholder="00.00"
                            handleOnChange={(e) => {
                                dispatch({
                                    type: RUNINFO_ACTIONS.SET_AMOUNT,
                                    payload: formatPrice(
                                        e.target.value,
                                        runInfo.amount
                                    ),
                                })
                            }}
                        />
                        <span>€</span>
                    </div>
                </div>
            )}

            {/* -------------------- Signature et Commentaire -------------------- */}

            <div>
                <h3 className="mb-2 font-bold text-gray-600">Signature</h3>
                <SignatureDocument
                    action={() =>
                        dispatch({
                            type: RUNINFO_ACTIONS.SHOW_SIGNATURE_FORM,
                            payload: true,
                        })
                    }
                    signature={runInfo.signature}
                />
            </div>
            {runInfo.signature ? (
                <div className="mt-4">
                    <div className="flex flex-col items-center rounded border border-gray-300 p-2">
                        {runInfo.signature.startsWith('[') ? (
                            <SignatureViewer
                                strokes={JSON.parse(runInfo.signature)}
                            />
                        ) : (
                            <img
                                src={runInfo.signature}
                                alt="Signature du patient"
                                className="h-40 max-w-full "
                            />
                        )}
                        <p className="mt-2 text-lg text-gray-600">
                            Signé le {runInfo.signatureDate} à{' '}
                            {runInfo.signatureLocation}
                        </p>
                    </div>
                </div>
            ) : null}
            <div className="flex flex-col">
                <p className="pb-1 font-bold text-gray-600">Commentaire</p>
                <TextAreaForms
                    value={runInfo.userComments}
                    placeholder="Un autre rendez-vous à noter ?"
                    onChange={(e) => {
                        dispatch({
                            type: RUNINFO_ACTIONS.SET_USER_COMMENTS,
                            payload: e.target.value,
                        })
                    }}
                />
            </div>

            {runInfo.status.id === 'done' && (
                <DeleteButton
                    label="Invalider le transport"
                    title="Invalider le transport"
                    action={changeRunStatus}
                    mobileSize={true}
                />
            )}

            {/* Affichage en plein écran d'une image */}
            {fullscreenImage && (
                <div
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80"
                    onClick={() => setFullscreenImage(null)}
                >
                    <img
                        src={fullscreenImage}
                        alt="Agrandissement"
                        className="max-h-full max-w-full"
                    />
                </div>
            )}

            {/* Modale du scanner (ScanContainer) */}
            {showScanner && (
                <ScanContainer
                    setShowScanner={setShowScanner}
                    docType={scannerDocType}
                    dispatch={dispatch}
                    runId={runInfo.id}
                />
            )}
            {showValidationModal && (
                <ActionModal
                    action={() => handleDeleteDocument()}
                    labelAction="Confirmation"
                    label="Êtes-vous sûr de vouloir valider cette action ?"
                    actionButton="Supprimer"
                    secondaryAction={closeValidationModal}
                />
            )}
        </div>
    )
}

export default OptionForm
