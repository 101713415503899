import React from 'react'
import SignatureViewer from '../../../Commons/SignatureViewer'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import { EditPencil } from 'iconoir-react'
const SocietySignaturePreview = ({ signature, onEdit }) => {
    return (
        <div className="flex flex-col space-y-4">
            <div className="flex items-center justify-between">
                <label className="flex w-full items-center justify-between font-semibold lg:w-1/3">
                    Signature
                </label>
                <SecondaryButton
                    label="Modifier"
                    action={onEdit}
                    icon={<EditPencil className="h-4 w-4" />}
                />
            </div>
            <div className="flex flex-col items-center rounded border border-gray-300 p-4">
                {signature && signature.startsWith('[') ? (
                    <SignatureViewer strokes={JSON.parse(signature)} />
                ) : null}
            </div>
        </div>
    )
}

export default SocietySignaturePreview
