import { handleTimeToDate } from '../dateUtils'

export const RUNINFO_ACTIONS = {
    GET_RUN_INFO: 'getRunInfo',
    SET_NAVIGATION_SCROLL: 'setNavigationScroll',
    SET_WIDTH: 'setWidth',
    SET_LOADING: 'setLoading',
    SHOW_MODAL: 'showModal',
    CLOSE_MODAL: 'closeModal',
    SET_DOCUMENTS: 'setDocuments',
    SET_USER_COMMENTS: 'setUserComments',
    SET_PAYMENT_METHOD: 'setPaymentMethod',
    SET_AMOUNT: 'setAmount',
    SET_EFFECTIVE_DEPARTURE_TIME: 'setEffectiveDepartureTime',
    SET_EFFECTIVE_ARRIVING_TIME: 'setEffectiveArrivingTime',
    SET_STATUS: 'setStatus',
    SHOW_CONTACT_MODAL: 'showContactModal',
    CLOSE_CONTACT_MODAL: 'closeContactModal',
    CONTACT_ALL: 'contactAll',
    SET_PERSON_TO_CONTACT: 'setPersonToContact',
    SHOW_ERROR: 'showError',
    SET_ERROR_FOR_PARTNER: 'setErrorForPartner',
    SHOW_SIGNATURE_FORM: 'showSignatureForm',
    SET_SIGNATURE: 'setSignature',
    SET_SIGNATURE_DATE: 'setSignatureDate',
    SET_SIGNATURE_LOCATION: 'setSignatureLocation',
    SET_SIGNATURE_ID: 'setSignatureId',
    CLEAR_SIGNATURE: 'clearSignature',
    GET_SIGNATURE_INFO: 'getSignatureInfo',
    SET_INVOICE_URL: 'setInvoiceUrl',
    ADD_DOCUMENT_URL: 'addDocumentURL',
    REMOVE_DOCUMENT_URL: 'removeDocumentURL',
}

export const RunInformationReducer = (runInfo, action) => {
    switch (action.type) {
        case RUNINFO_ACTIONS.SET_NAVIGATION_SCROLL:
            return {
                ...runInfo,
                runInfoColor:
                    action.payload.documentScrollLeft < runInfo.width / 2
                        ? 'text-light-dark-green'
                        : 'text-gray-300',
                patientInfoColor:
                    action.payload.documentScrollLeft !== 0 &&
                    action.payload.documentScrollLeft > runInfo.width / 2 &&
                    action.payload.documentScrollLeft <
                        runInfo.width * 2 - runInfo.width / 2
                        ? 'text-light-dark-green'
                        : 'text-gray-300',
                optionColor:
                    action.payload.documentScrollLeft >
                    runInfo.width + runInfo.width / 2
                        ? 'text-light-dark-green'
                        : 'text-gray-300',
            }

        case RUNINFO_ACTIONS.SET_WIDTH:
            return {
                ...runInfo,
                width: action.payload,
            }
        case RUNINFO_ACTIONS.SET_LOADING:
            return {
                ...runInfo,
                loading: action.payload,
                showContactModal: false,
            }

        case RUNINFO_ACTIONS.GET_RUN_INFO:
            return {
                ...runInfo,
                ...action.payload,
                loading: false,
            }
        case RUNINFO_ACTIONS.SHOW_MODAL:
            return {
                ...runInfo,
                showModal: true,
                effectiveArrivingTime:
                    runInfo.effectiveArrivingTime &&
                    runInfo.setEffectiveArrivingTimeByUser
                        ? new Date(runInfo.effectiveArrivingTime)
                        : new Date(),
            }
        case RUNINFO_ACTIONS.SHOW_CONTACT_MODAL:
            return {
                ...runInfo,
                showContactModal: true,
            }
        case RUNINFO_ACTIONS.CLOSE_MODAL:
            return {
                ...runInfo,
                showModal: false,
            }
        case RUNINFO_ACTIONS.CLOSE_CONTACT_MODAL:
            return {
                ...runInfo,
                showContactModal: false,
            }
        case RUNINFO_ACTIONS.SET_DOCUMENTS:
            return {
                ...runInfo,
                documents: action.payload,
                hasChanged: true,
            }
        case RUNINFO_ACTIONS.SET_USER_COMMENTS:
            return {
                ...runInfo,
                userComments: action.payload,
                hasChanged: true,
            }
        case RUNINFO_ACTIONS.SET_PAYMENT_METHOD:
            return {
                ...runInfo,
                paymentMethod: action.payload,
                runObject: action.payload,
                hasChanged: true,
            }
        case RUNINFO_ACTIONS.SET_AMOUNT:
            return {
                ...runInfo,
                amount: action.payload,
                hasChanged: true,
            }
        case RUNINFO_ACTIONS.SET_EFFECTIVE_DEPARTURE_TIME:
            return {
                ...runInfo,
                effectiveDepartureTime: handleTimeToDate(action.payload),
                hasChanged: true,
            }
        case RUNINFO_ACTIONS.SET_EFFECTIVE_ARRIVING_TIME:
            return {
                ...runInfo,
                effectiveArrivingTime: handleTimeToDate(action.payload),
                setEffectiveArrivingTimeByUser: true,
                hasChanged: true,
            }
        case RUNINFO_ACTIONS.SET_STATUS:
            return {
                ...runInfo,
                status: action.payload,
                loading: false,
            }
        case RUNINFO_ACTIONS.CONTACT_ALL:
            return {
                ...runInfo,
                contactAll: action.payload,
            }
        case RUNINFO_ACTIONS.SET_PERSON_TO_CONTACT:
            return {
                ...runInfo,
                personToContact: action.payload,
                hasChanged: true,
            }
        case RUNINFO_ACTIONS.SET_ERROR_FOR_PARTNER:
            return {
                ...runInfo,
                errorForPartner: true,
                loading: false,
            }
        case RUNINFO_ACTIONS.SHOW_SIGNATURE_FORM:
            return {
                ...runInfo,
                showSignatureForm: action.payload,
            }
        case RUNINFO_ACTIONS.SET_SIGNATURE:
            return {
                ...runInfo,
                signature: action.payload,
            }
        case RUNINFO_ACTIONS.SET_SIGNATURE_DATE:
            const input = action.payload
            const numbersOnly = input.replace(/[^\d]/g, '')
            let formattedDate = numbersOnly

            if (numbersOnly.length > 2) {
                formattedDate = `${numbersOnly.slice(0, 2)}/${numbersOnly.slice(
                    2
                )}`
            }
            if (numbersOnly.length > 4) {
                formattedDate = `${numbersOnly.slice(0, 2)}/${numbersOnly.slice(
                    2,
                    4
                )}/${numbersOnly.slice(4, 8)}`
            }

            return {
                ...runInfo,
                signatureDate: formattedDate,
            }
        case RUNINFO_ACTIONS.SET_SIGNATURE_LOCATION:
            return {
                ...runInfo,
                signatureLocation: action.payload,
            }
        case RUNINFO_ACTIONS.SET_SIGNATURE_ID:
            return {
                ...runInfo,
                signatureId: action.payload,
            }
        case RUNINFO_ACTIONS.CLEAR_SIGNATURE:
            return {
                ...runInfo,
                signature: null,
                signatureId: null,
                showSignatureForm: false,
            }
        case RUNINFO_ACTIONS.GET_SIGNATURE_INFO:
            return {
                ...runInfo,
                signature: action.payload.signature,
                signatureDate: action.payload.signatureDate,
                signatureLocation: action.payload.signatureLocation,
                signatureId: action.payload.signatureId,
            }
        case RUNINFO_ACTIONS.SET_INVOICE_URL:
            return {
                ...runInfo,
                invoiceUrl: action.payload,
            }
        case RUNINFO_ACTIONS.ADD_DOCUMENT_URL:
            return {
                ...runInfo,
                documentsUrl: action.payload,
            }
        case RUNINFO_ACTIONS.REMOVE_DOCUMENT_URL:
            return {
                ...runInfo,
                documentsUrl: runInfo.documentsUrl.filter(
                    (doc) => doc.id !== action.payload
                ),
            }
        default:
            return runInfo
    }
}

export const getInitialState = () => {
    return {
        run: null,
        runInfoColor: 'text-light-dark-green',
        patientInfoColor: 'text-gray-300',
        optionColor: 'text-gray-300',
        width: 0,
        loading: true,
        dateString: null,
        hasChanged: false,
        errorForPartner: false,
        signatureLocation: null,
        signatureDate: null,
        signature: null,
        setEffectiveArrivingTimeByUser: false,
    }
}
