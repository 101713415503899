import { useState, useEffect } from 'react'
import Camera from './Camera'
import Scanner from './Scanner'
import imageCompression from 'browser-image-compression'
import { Driver } from '../../../services/API/Entities/driver'
import { RUNINFO_ACTIONS } from '../../../services/Reducers/RunInformationReducer'

const ScanContainer = ({ setShowScanner, runId, dispatch, docType }) => {
    const openCvURL = 'https://docs.opencv.org/4.7.0/opencv.js'
    console.log('docType', docType)
    const [capturedImage, setCapturedImage] = useState(null)
    const [currentStep, setCurrentStep] = useState('camera') // 'camera' ou 'scanner'
    const [loadedOpenCV, setLoadedOpenCV] = useState(false)

    const handlePhotoCapture = (imageData) => {
        console.log('imageData', imageData)
        setCapturedImage(imageData)
        setCurrentStep('scanner')
    }

    const handleBack = () => {
        setCurrentStep('camera')
        setCapturedImage(null)
    }

    useEffect(() => {
        const scriptId = 'opencv-script'
        if (document.getElementById(scriptId)) {
            setLoadedOpenCV(true)
            return
        }
        const script = document.createElement('script')
        script.id = scriptId
        script.src = openCvURL
        script.async = true
        script.onload = () => {
            // Petite attente pour s'assurer que cv est bien initialisé
            setTimeout(() => {
                if (window.cv && window.cv.Mat) {
                    setLoadedOpenCV(true)
                }
            }, 1000)
        }
        document.body.appendChild(script)
    }, [])

    const saveUrl = async (file) => {
        try {
            // Compresser l'image
            const compressedFile = await imageCompression(file, {
                maxSizeMB: 0.2,
                maxWidthOrHeight: 1024,
                useWebWorker: true,
                fileType: 'image/webp',
            })

            // Préparer les données à envoyer
            const data = {
                file: compressedFile,
                docType,
                runId,
            }

            // Upload vers S3 via votre service (Driver.uploadToS3)
            const uploadResponse = await Driver.uploadToS3(data)

            console.log(uploadResponse.documentsUrl)
            const actionType =
                docType === 'invoice'
                    ? RUNINFO_ACTIONS.SET_INVOICE_URL
                    : RUNINFO_ACTIONS.ADD_DOCUMENT_URL

            if (docType === 'invoice') {
                dispatch({
                    type: actionType,
                    payload: URL.createObjectURL(compressedFile),
                })
            } else {
                dispatch({
                    type: actionType,
                    payload: uploadResponse.documentsUrl,
                })
            }
            setShowScanner(false)
        } catch (error) {
            console.error(
                "Erreur lors de la compression ou de l'upload :",
                error
            )
        }
    }

    if (!loadedOpenCV) {
        return <div>Chargement d'OpenCV...</div>
    }

    return (
        <div className="h-screen w-screen overflow-hidden">
            {currentStep === 'camera' ? (
                <Camera
                    onPhotoCapture={handlePhotoCapture}
                    setShowScanner={setShowScanner}
                />
            ) : (
                <Scanner
                    imageSrc={capturedImage}
                    onBack={handleBack}
                    loadedOpenCV={loadedOpenCV}
                    saveUrl={saveUrl}
                />
            )}
        </div>
    )
}

export default ScanContainer
