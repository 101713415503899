import React, { useState, useRef } from 'react'
import Loader from '../../../../Commons/Loaders/Loader'
import SignatureViewer from '../../../../Commons/SignatureViewer'
import UploadDocument from '../../../../DriverAccess/Files/UploadDocument'
import { SERIES_ACTIONS } from '../../../../../services/Reducers/SeriesReducer'
import ActionModal from '../../../../Modal/ActionModal'
import { Xmark } from 'iconoir-react'
import { Series } from '../../../../../services/API/Entities/series'

const SettingsSeriesForm = ({ series, dispatch }) => {
    const [docType, setDoctype] = useState(null)
    const [fullscreenImage, setFullscreenImage] = useState(null)
    const [showValidationModal, setShowValidationModal] = useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const scrollContainerRef = useRef(null)

    const handleScroll = (direction) => {
        if (scrollContainerRef.current) {
            const scrollAmount = 250
            const newScrollLeft =
                scrollContainerRef.current.scrollLeft +
                (direction === 'right' ? scrollAmount : -scrollAmount)
            scrollContainerRef.current.scrollTo({
                left: newScrollLeft,
                behavior: 'smooth',
            })
        }
    }

    const handleDeleteDocument = async () => {
        closeValidationModal()
        setIsDeleting(true)
        const data = {
            series: series.id,
            docType: docType,
        }
        try {
            await Series.deleteFromS3(data)

            dispatch({
                type: SERIES_ACTIONS.REMOVE_DOCUMENT_URL,
                payload: docType,
            })
        } catch (error) {
            console.error('Erreur lors de la suppression du document :', error)
        } finally {
            setIsDeleting(false)
        }
    }

    const openValidationModal = (doctype) => {
        setDoctype(doctype)
        setShowValidationModal(true)
    }

    const closeValidationModal = () => {
        setDoctype(null)
        setShowValidationModal(false)
    }

    const renderPMTSection = () => {
        return (
            <div className="space-y-2">
                {isDeleting ? (
                    <Loader className="h-5 w-5 animate-spin" />
                ) : (
                    <>
                        <div className="mb-2 flex items-center">
                            <h3 className="font-bold text-gray-600">PMT</h3>
                            <span className="ml-2 text-sm text-gray-500">
                                ({series.documentsUrl?.length || 0}/3)
                            </span>
                        </div>

                        {series.documentsUrl?.length > 0 && (
                            <div className="relative">
                                {series.documentsUrl.length > 1 && (
                                    <>
                                        <button
                                            onClick={() => handleScroll('left')}
                                            className="absolute left-0 top-1/2 z-10 -translate-y-1/2 rounded-full bg-white bg-opacity-75 p-2 shadow-md"
                                        >
                                            <i className="fas fa-chevron-left" />
                                        </button>
                                        <button
                                            onClick={() =>
                                                handleScroll('right')
                                            }
                                            className="absolute right-0 top-1/2 z-10 -translate-y-1/2 rounded-full bg-white bg-opacity-75 p-2 shadow-md"
                                        >
                                            <i className="fas fa-chevron-right" />
                                        </button>
                                    </>
                                )}

                                <div
                                    ref={scrollContainerRef}
                                    className="scrollbar-hide flex space-x-4 overflow-x-auto"
                                >
                                    {series.documentsUrl.map(
                                        (document, index) => (
                                            <div
                                                key={index}
                                                className="relative flex-none"
                                            >
                                                <img
                                                    src={document.url}
                                                    alt={`PMT ${index + 1}`}
                                                    className="h-48 w-48 cursor-pointer rounded-lg border object-contain"
                                                    onClick={() =>
                                                        setFullscreenImage(
                                                            document.url
                                                        )
                                                    }
                                                />
                                                <button
                                                    onClick={() =>
                                                        openValidationModal(
                                                            document.id
                                                        )
                                                    }
                                                    className="absolute -top-2 right-0 rounded-full bg-white p-1 text-red-500 shadow-md"
                                                    disabled={isDeleting}
                                                >
                                                    {isDeleting ? (
                                                        <Loader className="h-5 w-5 animate-spin" />
                                                    ) : (
                                                        <Xmark className="h-6 w-6" />
                                                    )}
                                                </button>
                                            </div>
                                        )
                                    )}

                                    {series.documentsUrl.length < 3 && (
                                        <div className="w-48">
                                            <UploadDocument
                                                docType="prescription"
                                                dispatch={dispatch}
                                                action={SERIES_ACTIONS}
                                                seriesId={series.id}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {(!series.documentsUrl ||
                            series.documentsUrl.length === 0) && (
                            <div className="w-full">
                                <UploadDocument
                                    docType="prescription"
                                    dispatch={dispatch}
                                    action={SERIES_ACTIONS}
                                    seriesId={series.id}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        )
    }

    return (
        <div className="flex w-full flex-col space-y-4 pb-10">
            <div className="flex items-center justify-between pb-10 pt-6">
                <h1 className="text-2xl font-semibold">
                    Paramètres de la série
                </h1>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="rounded-lg bg-white p-6 shadow-md">
                    <div className="space-y-6">
                        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                            {series.signature ? (
                                <div className="flex flex-col">
                                    <div className="mb-2 flex items-center justify-between">
                                        <p className="mb-2 font-bold text-gray-600">
                                            Signature du patient
                                        </p>
                                    </div>
                                    <div className="flex flex-col items-center rounded border border-gray-300">
                                        {series.signature.startsWith('[') ? (
                                            <SignatureViewer
                                                strokes={JSON.parse(
                                                    series.signature
                                                )}
                                            />
                                        ) : (
                                            <img
                                                src={series.signature}
                                                alt="Signature du patient"
                                                className="h-40 max-w-full"
                                            />
                                        )}
                                    </div>
                                    <div className="mb-4 text-lg text-gray-600">
                                        <div className="flex flex-col space-y-4">
                                            <div className="flex items-baseline pt-4">
                                                <span className="mr-2">
                                                    Fait le
                                                </span>
                                                <input
                                                    type="text"
                                                    value={series.signatureDate}
                                                    placeholder="jj/mm/aaaa"
                                                    onChange={(e) =>
                                                        dispatch({
                                                            type: SERIES_ACTIONS.SET_SIGNATURE_DATE,
                                                            payload:
                                                                e.target.value,
                                                        })
                                                    }
                                                    className="flex-grow border-b border-secondary-color text-center font-bold focus:border-primary-color focus:outline-none"
                                                />
                                            </div>
                                            <div className="mt-2 flex items-baseline">
                                                <span className="mr-11">à</span>
                                                <input
                                                    type="text"
                                                    placeholder="lieu"
                                                    value={
                                                        series.signatureLocation
                                                    }
                                                    onChange={(e) =>
                                                        dispatch({
                                                            type: SERIES_ACTIONS.SET_SIGNATURE_LOCATION,
                                                            payload:
                                                                e.target.value,
                                                        })
                                                    }
                                                    className="flex-grow border-b border-secondary-color text-center font-bold focus:border-primary-color focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            <div className="col-span-1">
                                {renderPMTSection()}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showValidationModal && (
                <ActionModal
                    action={handleDeleteDocument}
                    labelAction="Confirmation"
                    label="Êtes-vous sûr de vouloir valider cette action ?"
                    actionButton="Supprimer"
                    secondaryAction={closeValidationModal}
                />
            )}
            {fullscreenImage && (
                <div
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80"
                    onClick={() => setFullscreenImage(null)}
                >
                    <img
                        src={fullscreenImage}
                        alt="Agrandissement"
                        className="max-h-full max-w-full"
                    />
                </div>
            )}
        </div>
    )
}

export default SettingsSeriesForm
