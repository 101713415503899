import { concat } from '../pathsAndFilters'
import { formatHydraData } from '../../api'
import { apiClient, withErrorHandling } from '../apiClient'

const PATH_KEY = '/series'

export const Series = {
    fetchAll: async (search, archived) => {
        let path = '/get_series'
        if (search) path = concat(path, 'search=' + search)
        if (archived) path = concat(path, 'archived=' + archived)
        return formatHydraData(await apiClient.get(path))
    },
    deleteFromS3: withErrorHandling(async (data) => {
        const response = await apiClient.put(
            `/api/documents/series/s3-delete`,
            {
                seriesId: data.series, // L'ID du run qu'on souhaite compléter
                docType: data.docType,
            }
        )
        return response.data
    }),
    uploadToS3: withErrorHandling(async (data) => {
        const response = await apiClient.post(
            `/api/documents/series/s3-presigned-url`,
            {
                fileName: data.file.name,
                fileType: data.file.type,
                seriesId: data.series, // L'ID du run qu'on souhaite compléter
                docType: data.docType,
            }
        )
        const { url } = response
        await fetch(url, {
            method: 'PUT',
            body: data.file,
            headers: { 'Content-Type': data.file.type },
        })
        return response
    }),
}
