import React from 'react'
import SignaturePad from 'react-signature-pad-wrapper'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import { Erase } from 'iconoir-react'
import ModalSkeleton from '../../../Modal/ModalSkeleton'

const SocietySignatureForm = ({ signatureRef, onClose, onSave, isPending }) => {
    const handleClear = () => {
        signatureRef.current.clear()
    }

    return (
        <ModalSkeleton
            Component={() => (
                <div className="w-full rounded-lg bg-white p-6">
                    <div className="mb-4 flex justify-between">
                        <SecondaryButton
                            label="Effacer"
                            action={handleClear}
                            icon={<Erase />}
                        />
                    </div>
                    <div className="mb-4 w-full select-none rounded-md border-2 border-gray-300">
                        <SignaturePad
                            options={{
                                minWidth: 1.5,
                                maxWidth: 3,
                                penColor: 'rgb(0,0,0)',
                                backgroundColor: 'rgb(255,255,255)',
                            }}
                            className="h-40 w-full select-none"
                            canvasProps={{
                                width: 250,
                                height: 300,
                            }}
                            ref={signatureRef}
                        />
                    </div>
                </div>
            )}
            label="Signature du dirigeant"
            handleCloseModal={onClose}
            handleForm={onSave}
            buttonLabel="Confirmer la signature"
            loader={isPending}
            disabledSubmit={isPending}
        />
    )
}

export default SocietySignatureForm
